import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";
import sliceKeys from "../sliceKeys";
import { setUploadProgress, retryUploadProgress } from "./index";
import moment from "moment";

const getAllAssetInventoryMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory/getAllAssetInventory?currentPage=${payload?.currentPage}&pageSize=${payload?.rowsPerPage}&sortField=created_at&sortOrder=desc&filters=${payload?.filters}`
  );
};

const getAssetInventoryAreaMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory-report-export/getAllAssetInventoryArea/${payload.id}`
  );
};

const getAssetInventoryByIDMethod = ({ payload, config }) => {
  return axios.get(`asset-inventory/getAssetInventoryByID/${payload.id}`);
};

const getAssetInventoryItemByIDMethod = ({ payload, config }) => {
  return axios.get(`asset-inventory/getAssetInventoryItemByID/${payload.id}`);
};
const putAssetInventoryItemMethod = ({ payload, config }) => {
  return axios.put(`asset-inventory/putAssetInventoryItem/${payload.id}`, {
    inventory_at: moment().format("YYYY-MM-DD HH:mm:ss"),
    status_id: payload.status_id,
    final_status_id: payload.final_status_id,
    note: payload.note,
    unique_key: payload.unique_key,
    asset_inventory_item_critterias: payload.asset_inventory_item_critterias,
  });
};

const postAssetInventoryUserMethod = ({ payload, config }) => {
  return axios.post(
    `asset-inventory/postAssetInventoryUser/${payload.id}`,
    payload.data
  );
};

const putAssetInventoryUserMethod = ({ payload, config }) => {
  return axios.put(
    `asset-inventory/putAssetInventoryUser/${payload.id}`,
    payload.data
  );
};

const deleteAssetInventoryUserMethod = ({ payload, config }) => {
  config = { data: payload.data };
  return axios.delete(
    `asset-inventory/deleteAssetInventoryUser/${payload.id}`,
    config
  );
};

const getAllDepartmentAndWarehouseMethod = ({ payload, config }) => {
  return axios.get(`asset-inventory/getAllDepartmentAndWarehouse`);
};

const getAllAssetInventoryPositionMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory/getAllAssetInventoryPosition?currentPage=${
      payload.currentPage
    }&pageSize=${payload.rowsPerPage}&filters=${encodeURIComponent(
      payload.searchQuery
    )}`
  );
};

const postAssetInventoryMethod = ({ payload, config }) => {
  return axios.post(`asset-inventory/postAssetInventory`, payload, config);
};

const putAssetInventoryMethod = ({ payload, config }) => {
  return axios.put(
    `asset-inventory/putAssetInventory/${payload.id}`,
    payload.data
  );
};

const deleteAssetInventoryMethod = ({ payload, config }) => {
  return axios.delete(`asset-inventory/deleteAssetInventory/${payload.id}`);
};

const exportExcelAssetInventoryItemMethod = ({ payload, config }) => {
  return axios.download(
    `asset-inventory/exportExcelAssetInventoryItem/${payload}`
  );
};

const getAllAssetInventoryUserMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory/getAllAssetInventoryUser/${payload.id}?currentPage=${payload.currentPage}&pageSize=${payload.rowsPerPage}`
  );
};

const getAllAssetInventoryItemMethod = ({ payload, config }) => {
  return axios.post(
    `asset-inventory/getAllAssetInventoryItem/${payload.id}?currentPage=${
      payload.currentPage
    }&pageSize=${payload.rowsPerPage}&filters=${encodeURIComponent(
      payload.searchQuery
    )}`,
    payload.searchData
  );
};

const putAssetInventoryItemCriteriaMethod = ({ payload, config }) => {
  return axios.put(
    `asset-inventory/putAssetInventoryItemCriteria/${payload.id}`,
    {
      description: payload.description,
      asset_inventory_criteria_option_id:
        payload.asset_inventory_criteria_option_id,
    }
  );
};
const uploadFileAssetInventoryItemCriteriaMethod = ({ payload, config }) => {
  const formData = new FormData();
  formData.append("file", payload.file, payload.file.name);
  return axios.post(
    `asset-inventory/uploadFileAssetInventoryItemCriteria/${payload.id}/${payload.unique_key}`,
    formData,
    {
      ...config,
      "Content-Type": "multipart/form-data",
      onUploadProgress: async (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentageProgress = Math.floor((loaded / total) * 100);
        await payload.dispatch(
          setUploadProgress({
            id: payload.index,
            progress: percentageProgress,
          })
        );
      },
    }
  );
};
const deleteFileAssetInventoryItemCriteriaMethod = ({ payload, config }) => {
  return axios.delete(
    `asset-inventory/deleteFileAssetInventoryItemCriteria/${payload.id}`
  );
};
const getAllAssetInventoryCriteriaByAssetInventoryIdMethod = ({
  payload,
  config,
}) => {
  return axios.get(
    `asset-inventory/getAllAssetInventoryCriteriaByAssetInventoryId/${
      payload.id
    }?filers=${payload.filters || ""}${
      (payload.currentPage && `&currentPage=${payload.currentPage}`) || ""
    }${(payload.pageSize && `&pageSize=${payload.pageSize}`) || ""}${
      (payload.sortField && `&sortFieldF=${payload.sortField}`) || ""
    }${(payload.sortOrder && `&sortOrder=${payload.sortOrder}`) || ""}`
  );
};
const revertAssetInventoryItemCriteriaMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory/revertAssetInventoryItemCriteria/${payload.unique_key}`
  );
};

const getAssetCriteriaByAssetInventoryIdMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory/getAllAssetInventoryCriteriaByAssetInventoryId/${payload.id}`
  );
};
const putAssetInventoryCriteriaMethod = ({ payload, config }) => {
  return axios.put(
    `asset-inventory/putAssetInventoryCriteria/${payload.id}`,
    payload.data
  );
};

const postAssetInventoryCriteriaMethod = ({ payload, config }) => {
  return axios.post(
    `asset-inventory/postAssetInventoryCriteria/${payload.id}`,
    payload.data
  );
};
const deleteAssetInventoryCreiteriaMethod = ({ payload, config }) => {
  return axios.delete(
    `asset-inventory/deleteAssetInventoryCriteria/${payload.id}`
  );
};
const getAssetInInventoryMethod = ({ payload, config }) => {
  return axios.get(`asset-inventory-report/getAssetInInventory/${payload.id}`);
};

const approveAssetInventoryRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-inventory/approveAssetInventoryRequest/${payload.id}`
  );
};

const refuseAssetInventoryRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-inventory/refuseAssetInventoryRequest/${payload.id}`,
    payload.data
  );
};

const responseSuccess = (res) => {
  if (!res) return res;
  if (res.response) return { ...res.response.data };
  return res.data;
};

export const getAllAssetInventory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetInventoryMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetInventoryByID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetInventoryByIDMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetInventoryArea = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetInventoryAreaMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllDepartmentAndWarehouse = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllDepartmentAndWarehouseMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetCriteriaByAssetInventoryId = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetCriteriaByAssetInventoryIdMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const exportExcelAssetInventoryItem = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      exportExcelAssetInventoryItemMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const postAssetInventoryCriteria = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAssetInventoryCriteriaMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteAssetInventoryCriteria = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteAssetInventoryCreiteriaMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetInInventory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetInInventoryMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetInventoryItemByID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetInventoryItemByIDMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAssetInventoryUser = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetInventoryUserMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAssetInventoryItem = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetInventoryItemMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const postAssetInventoryUser = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAssetInventoryUserMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAssetInventoryUser = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetInventoryUserMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAssetInventoryCriteria = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetInventoryCriteriaMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteAssetInventoryUser = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteAssetInventoryUserMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const postAssetInventory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAssetInventoryMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAssetInventory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetInventoryMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteAssetInventory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteAssetInventoryMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAssetInventoryPosition = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetInventoryPositionMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAssetInventoryItem = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetInventoryItemMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const putAssetInventoryItemCriteria = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetInventoryItemCriteriaMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const uploadFileAssetInventoryItemCriteria = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      uploadFileAssetInventoryItemCriteriaMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const deleteFileAssetInventoryItemCriteria = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteFileAssetInventoryItemCriteriaMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllAssetInventoryCriteriaByAssetInventoryId = (
  payload,
  config
) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetInventoryCriteriaByAssetInventoryIdMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const revertAssetInventoryItemCriteria = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      revertAssetInventoryItemCriteriaMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const approveAssetInventoryRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      approveAssetInventoryRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const refuseAssetInventoryRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      refuseAssetInventoryRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
