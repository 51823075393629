import React, { } from "react";
import { Link, useParams, } from "react-router-dom";
import "./tabs.scss"
import { useTranslation } from "react-i18next";

const TabItem = props => {
	const {t} = useTranslation()
	// item: {icon: false, name: "", path: null, value: ""}
	const { 
		item, 
		icon = false, 
		current, 
		actionMethod, 
		chooseTab, //
		redirect,  
		flexGrow = false, 
	} = props;
	const handleChoose = async () => {
		typeof chooseTab === 'function' && await chooseTab()
		typeof actionMethod === 'function' && await actionMethod(item)
	}

	return (
		redirect
		? (<Link to={`${item.path}`} className={`tabs__item ${flexGrow? "tabs__item--flex-grow" : ""} ${current?.value === item?.value? "tabs__item--active" : "" }`} onClick={() => handleChoose()}>
			{icon && (<img src={item.icon} alt="info icon" className={`tabs__icon ${current?.value === item?.value? "tabs__icon--active" : "" }`}/>)}
			<div className={`tabs__text ${current?.value === item?.value? "tabs__text--active" : "" }`}>{t(item.name)}</div>
		</Link>)
		: (<div className={`tabs__item ${flexGrow? "tabs__item--flex-grow" : ""} ${current?.value === item?.value? "tabs__item--active" : "" }`} onClick={() => handleChoose()}>
			{icon && (<img src={item.icon} alt="info icon" className={`tabs__icon ${current?.value === item?.value? "tabs__icon--active" : "" }`}/>)}
			<div className={`tabs__text ${current?.value === item?.value? "tabs__text--active" : "" }`}>{t(item.name)}</div>
		</div>)
	)
}

const Tabs = props => {
	const { 
		list = [],
		icon = false,
		redirect = false,
		current = {
			name: "", 
			value: "", 
			path: null, 
			icon: false, 
		}, 
		flexGrow = false, 
	} = props

	return (
		<div className="tabs__container">
			{Object.keys(list).length
				? Object.keys(list).map((key, index) => 
					(<TabItem 
						key={index*-1} 
						item={list[key]} 
						icon={icon} 
						current={current} 
						redirect={redirect} {...props} 
						flexGrow={flexGrow}
					/>))
				: null
			}
		</div>
	)
}

export default Tabs